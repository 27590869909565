html, body, #root, .App, #map {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.react-geo-uploadbutton {
  cursor: pointer;
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.react-geo-uploadbutton input[type="file"] {
  cursor: pointer;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}
.react-geo-uploadbutton ::-webkit-file-upload-button {
  cursor: pointer;
}
button.react-geo-simplebutton {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
}
button.react-geo-simplebutton:focus {
  background-color: #4ba9ff;
  border-color: #ffffff;
}
button.react-geo-simplebutton:hover {
  background-color: #4ba9ff;
  border-color: #ffffff;
}
button.react-geo-simplebutton:disabled {
  background-color: #fff;
  border-color: #e6e6e6;
}
button.react-geo-measurebutton {
  color: #fff;
  background-color: #1890ff;
  border-color: #e6e6e6;
}
button.react-geo-measurebutton:focus {
  background-color: #4ba9ff;
  border-color: #ffffff;
}
button.react-geo-measurebutton:hover {
  background-color: #4ba9ff;
  border-color: #ffffff;
}
button.react-geo-measurebutton:disabled {
  background-color: #fff;
  border-color: #e6e6e6;
}
button.react-geo-measurebutton.btn-pressed {
  background-color: #005cb1;
  border-color: #e6e6e6;
}
.react-geo-measure-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
}
.react-geo-measure-tooltip-dynamic {
  opacity: 1;
  font-weight: bold;
}
.react-geo-measure-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.react-geo-measure-tooltip-dynamic:before,
.react-geo-measure-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.react-geo-measure-tooltip-static:before {
  border-top-color: #ffcc33;
}
button.react-geo-digitizebutton {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
}
button.react-geo-digitizebutton:focus {
  background-color: #4ba9ff;
  border-color: #ffffff;
}
button.react-geo-digitizebutton:hover {
  background-color: #4ba9ff;
  border-color: #ffffff;
}
button.react-geo-digitizebutton:disabled {
  background-color: #fff;
  border-color: #e6e6e6;
}
.vertical-toggle-group {
  display: flex;
  flex-direction: column;
}
.horizontal-toggle-group {
  display: flex;
  flex-direction: row;
}
button.react-geo-togglebutton {
  color: #fff;
  background-color: #1890ff;
  border-color: #e6e6e6;
}
button.react-geo-togglebutton:focus {
  background-color: #4ba9ff;
  border-color: #ffffff;
}
button.react-geo-togglebutton:hover {
  background-color: #4ba9ff;
  border-color: #ffffff;
}
button.react-geo-togglebutton:disabled {
  background-color: #fff;
  border-color: #e6e6e6;
}
button.react-geo-togglebutton.btn-pressed {
  background-color: #005cb1;
  border-color: #e6e6e6;
}
.react-geo-circlemenuitem {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1.3em;
  pointer-events: all;
}
.react-geo-circlemenu {
  border: dashed 1px;
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0) 0%, rgba(200, 199, 58, 0.65) 100%);
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0) 0%, rgba(200, 199, 58, 0.65) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(200, 199, 58, 0.65) 100%);
}
.add-wms-layer-checkbox-line {
  padding: 5px;
  margin: 1px;
  display: flex;
  background: #ffffff;
  flex: 1;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.add-wms-layer-checkbox-line .add-wms-add-info-icon {
  margin-left: 5px;
}
.add-wms-panel .body {
  display: flex;
  flex-direction: column;
}
.add-wms-panel .body .ant-checkbox-group {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}
.add-wms-panel .body .add-wms-layer-checkbox-line {
  padding: 5px;
  margin: 1px;
  display: flex;
  background: #ffffff;
  flex: 1;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.add-wms-panel .body .add-wms-layer-checkbox-line .add-wms-add-info-icon {
  margin-left: 5px;
}
.add-wms-panel .body .react-geo-titlebar .react-geo-simplebutton {
  margin-right: 5px;
}
.react-geo-feature-grid .react-geo-feature-grid-row.row-hover > td {
  background: #e6f7ff;
}
.react-geo-propertygrid {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.react-geo-propertygrid .ant-spin-nested-loading {
  height: 100%;
}
.react-geo-propertygrid .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.react-geo-propertygrid .ant-spin-nested-loading .ant-spin-container .ant-table {
  flex: 1;
}
.react-geo-propertygrid .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-fixed-header .ant-table-header {
  margin-bottom: inherit !important;
  padding-bottom: inherit !important;
}
.react-geo-propertygrid .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.react-geo-propertygrid .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-header {
  overflow: inherit;
}
.react-geo-propertygrid .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body {
  overflow: auto;
}
.react-geo-propertygrid .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content th {
  text-align: center;
}
.react-geo-propertygrid .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content tr:nth-child(odd) {
  background-color: rgba(24, 144, 255, 0.05);
}
.react-geo-propertygrid .ant-pagination {
  width: 100%;
  text-align: center;
}
.react-geo-layertree-node {
  transition: opacity 1s;
}
.react-geo-layertree-node.out-off-range {
  opacity: 0.5;
}
.react-geo-floatingmaplogo {
  position: absolute;
  left: 5px;
  bottom: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  z-index: 10;
}
.react-geo-titlebar {
  display: flex;
  align-items: center;
  overflow: auto;
  background-color: #e6e6e6;
  color: #1890ff;
  font-weight: bold;
}
.react-geo-titlebar .title {
  margin: 5px;
  flex: 1;
}
.react-geo-titlebar .controls {
  margin: 5px;
  display: flex;
}
.react-geo-titlebar .controls > button {
  margin-right: 2px;
}
.react-geo-panel {
  z-index: 100;
}
.react-geo-panel .body {
  background-color: #fff;
}
.react-geo-panel .resize-handle:hover.resize-handle-right {
  border-right: 5px solid rgba(24, 144, 255, 0.5);
}
.react-geo-panel .resize-handle:hover.resize-handle-left {
  border-left: 5px solid rgba(24, 144, 255, 0.5);
}
.react-geo-panel .resize-handle:hover.resize-handle-top {
  border-top: 5px solid rgba(24, 144, 255, 0.5);
}
.react-geo-panel .resize-handle:hover.resize-handle-bottom {
  border-top: 5px solid rgba(24, 144, 255, 0.5);
}
.react-geo-panel .resize-handle:hover.resize-handle-bottom-right {
  border-bottom: 5px solid rgba(24, 144, 255, 0.5);
  border-right: 5px solid rgba(24, 144, 255, 0.5);
}
.react-geo-panel .resize-handle:hover.resize-handle-bottom-left {
  border-bottom: 5px solid rgba(24, 144, 255, 0.5);
  border-left: 5px solid rgba(24, 144, 255, 0.5);
}
.react-geo-panel .resize-handle:hover.resize-handle-top-right {
  border-top: 5px solid rgba(24, 144, 255, 0.5);
  border-right: 5px solid rgba(24, 144, 255, 0.5);
}
.react-geo-panel .resize-handle:hover.resize-handle-top-left {
  border-top: 5px solid rgba(24, 144, 255, 0.5);
  border-left: 5px solid rgba(24, 144, 255, 0.5);
}
.vertical-toolbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vertical-toolbar button {
  margin-top: 5px;
}
.horizontal-toolbar {
  line-height: 30px;
  pointer-events: all;
  transition: all .3s;
}
.horizontal-toolbar button {
  margin-right: 5px;
  top: 5px;
}
.react-geo-userchip {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.react-geo-userchip .username {
  margin-left: 5px;
}
.react-geo-window-portal {
  z-index: 1000;
  position: inherit !important;
  -moz-box-shadow: 2px 2px 10px -2px #cccccc, -2px 2px 10px -2px #cccccc;
  -webkit-box-shadow: 2px 2px 10px -2px #cccccc, -2px 2px 10px -2px #cccccc;
  box-shadow: 2px 2px 10px -2px #cccccc, -2px 2px 10px -2px #cccccc;
}
.react-geo-window-portal .react-geo-simplebutton {
  background-color: #fff;
  color: #1890ff;
}
.react-geo-window-portal .react-geo-simplebutton:hover {
  background-color: #4ba9ff;
  color: #ffffff;
}
.react-geo-window-portal .ant-modal-header {
  padding: 1px;
  border-bottom: 2px dotted rgba(24, 144, 255, 0.25);
  background-color: #ffffff;
}
.react-geo-window-portal .ant-modal-header .title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
